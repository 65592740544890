import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import ContainerApp from './ContainerApp.vue'
import wb from "./registerServiceWorker";
import './registerServiceWorker'


Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.prototype.$workbox = wb;

import router from "@/router";

new Vue({
  render: (h) => h(ContainerApp),
  router,
}).$mount('#app')
