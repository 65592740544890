import { render, staticRenderFns } from "./ContainerApp.vue?vue&type=template&id=31424e2d&scoped=true&"
var script = {}
import style0 from "./ContainerApp.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ContainerApp.vue?vue&type=style&index=1&id=31424e2d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31424e2d",
  null
  
)

export default component.exports