import Vue from 'vue'
import VueRouter from 'vue-router'

//
import Cookies from 'js-cookie'

// import Home from '@/views/Home.vue'

import NotFound from '@/components/NotFound.vue'

Vue.use(VueRouter)

const isAuthenticatedGuard = (to, from, next) => {
  const isAuthenticated = Cookies.get('token')

  if (isAuthenticated) {
    next({ name: 'home' })
  } else {
    next()
  }
}

const isNotAuthenticatedGuard = (to, from, next) => {
  const isAuthenticated = Cookies.get('token')

  if (!isAuthenticated) {
    // redirect the user to the login page
    next({ name: 'login' })
  } else {
    next()
  }
}

const routes = [
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/App.vue'),
    beforeEnter: isNotAuthenticatedGuard,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/Login.vue'),
    beforeEnter: isAuthenticatedGuard,
    meta: {
      auth: false
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/ForgotPassword.vue'),
    beforeEnter: isAuthenticatedGuard,
    meta: {
      auth: false
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "auth" */ '@/components/Auth/ResetPassword.vue'),
    beforeEnter: isAuthenticatedGuard,
    meta: {
      auth: false
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
